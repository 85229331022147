<template>
  <div>
    <div class="mb-1">
      <b-button
        variant="warning"
        @click="handleBack"
      >
        {{$t('message.back')}}
      </b-button>
    </div>

    <b-row>
      <b-col lg="7">
        <b-card v-if="clientData !== null">
          <b-row>
            <b-col md="5">
              <div class="d-flex mb-1">
                <b-avatar size="5rem"></b-avatar>
                <div class="p-1">
                  <h4>
                    {{ $t("data.firstNmae") }}: {{ clientData.first_name }}
                  </h4>
                </div>
              </div>
            </b-col>

            <b-col cols="12" class="pt-2">
              <b-row>
                <b-col cols="6">
                  <div class="d-flex">
                    <b-icon icon="person" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">{{ $t("message.Full_name") }}:</p>
                  </div>
                </b-col>
                <b-col cols="6">
                  <p class="fs-17">{{ clientData.full_name }}</p>
                </b-col>
                <b-col cols="6">
                  <div class="d-flex">
                    <b-icon icon="person" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">{{ $t("message.Email") }}:</p>
                  </div>
                </b-col>
                <b-col cols="6">
                  <p class="fs-17">{{ clientData.email }}</p>
                </b-col>

                <b-col cols="6" class="mt-cols">
                  <div class="d-flex">
                    <b-icon icon="telephone" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">{{ $t("data.mobile_number") }}:</p>
                  </div>
                </b-col>
                <b-col cols="6" class="mt-cols">
                  <p class="fs-17">{{ clientData.contacts.length ? clientData.contacts[0].number: '' }}</p>
                </b-col>

                <b-col
                  cols="6"
                  v-if="clientData.contacts[1]"
                  class="mt-cols"
                >
                  <div class="d-flex">
                    <b-icon icon="telephone" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">{{ $t("data.mobile_number") }}:</p>
                  </div>
                </b-col>
                <b-col
                  cols="6"
                  v-if="clientData.contacts[1]"
                  class="mt-cols"
                >
                  <p class="fs-17">{{ clientData.contacts[1].number }}</p>
                </b-col>

                <b-col cols="6" class="mt-cols">
                  <div class="d-flex">
                    <b-icon icon="calendar2-date" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">{{ $t("data.date_birthday") }}:</p>
                  </div>
                </b-col>
                <b-col cols="6" class="mt-cols">
                  <p class="fs-17">{{ clientData.birthdate }}</p>
                </b-col>
                <b-col cols="6" class="mt-cols">
                  <div class="d-flex">
                    <b-icon icon="clipboard" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">Notes:</p>
                  </div>
                </b-col>
                <b-col cols="6" class="mt-cols">
                  {{ data.notes }}
                </b-col>

                <b-col v-if="clientData.passport_serial" cols="6" class="mt-cols">
                  <div class="d-flex">
                    <b-icon icon="book-half" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">
                      {{ $t("message.Passport_serial") }}:
                    </p>
                  </div>
                </b-col>
                <b-col v-if="clientData.passport_serial" cols="6" class="mt-cols">
                  <p class="fs-17">{{ clientData.passport_serial }}</p>
                </b-col>

                <b-col v-if="clientData.passport_number" cols="6" class="mt-cols">
                  <div class="d-flex">
                    <b-icon icon="book-half" scale="1.5"></b-icon>
                    <p class="ml-1 fs-17">
                      {{ $t("message.Passport_number") }}:
                    </p>
                  </div>
                </b-col>
                <b-col v-if="clientData.passport_number" cols="6" class="mt-cols">
                  <p class="fs-17">{{ clientData.passport_number }}</p>
                </b-col>

              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="mt-2">
          <div v-if="data">
            <div v-if="data.visa_result">
              <b-button
                :loading="downloadLoading"
                :disabled="downloadLoading"
                variant="warning"
                @click="downloadClientVisa(data.visa_result)"
              >
                {{ $t('Download') }}
                <b-icon
                  icon="download"
                />
            </b-button>
            </div>
          </div>
          <div class="mt-2">
            <input type="file" id="clientVisaId" class="hidden" @change="handleVisaUpload">
            <b-button variant="info" @click="handleClientVisaUpload">{{ $t('UploadClientVisa') }}</b-button>
            <div v-if="visaUploadFile" class="w-full d-flex justify-content-end mt-4">
              <b-button variant="success" @click="submitVisaUpload">{{ $t('SubmitBtn') }}</b-button>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col lg="5">
        <b-card v-if="data !== null">
          <h4>{{$t('message.visa_text')}}</h4>
          <hr>
          <b-row>
            <b-col v-if="data.author" cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="person" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('Author')}}:</p>
              </div>
            </b-col>
            <b-col v-if="data.author" cols="6" class="mt-cols">
              <b class="fs-17">{{data.author.full_name}}</b>
            </b-col>

            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="wallet" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('message.Currency')}}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{data.currency.currency}}</b>
            </b-col>


            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="wallet2" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('message.Price')}}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{parseInt(data.price)}}</b>
            </b-col>

            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="wallet2" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('message.Fee')}}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{parseInt(data.fee)}}</b>
            </b-col>

            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="signpost-split" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('message.Country1')}}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{data.country.name_ru}}</b>
            </b-col>

            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="signpost-split" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{ $t('EnterData') }}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{data.enter_date}}</b>
            </b-col>

            <b-col cols="6" class="mt-cols">
              <div class="d-flex">
                <b-icon icon="signpost-split" scale="1.5"></b-icon>
                <p class="fs-17 ml-1">{{$t('ReleaseDate')}}:</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-cols">
              <b class="fs-17">{{data.release_date}}</b>
            </b-col>
            <b-col cols="12" class="mt-cols">
              <div class="d-flex">
                <a :href="data.passport_link" target="_blank" class="fs-17">{{$t('DownloadClientPassword')}}</a>
              </div>
            </b-col>
          </b-row>
          <b-row v-for="(item, index) in data.custom_field_answers" :key="index" class="mt-1">
            <b-col cols="6" class="pl-2">
              <p v-if="item.custom_field" class="fs-17">
                {{ item.custom_field.field_name }}
              </p>
            </b-col>
            <b-col v-if="item.custom_field" cols="6">
              <p v-if="item.custom_field.field_type === 'text'" class="fs-17">
                {{ item.answer }}
              </p>
              <a v-if="item.custom_field.field_type === 'file'" @click="downloadClientVisa(item.answer)">{{ $t('Download') }}</a>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {showVisa, uploadVisaResult, getS3PermanentFile} from '@/api/visa/index';
  import moment from 'moment'

  export default {
    name: "Tours-Details",
    data() {
      return {
        id: this.$route.params.visaDetailsId,
        data: null,
        clientData: null,
        visaUploadFile: null,
        downloadLoading: false,
      }
    },
    created() {
      showVisa(this.$route.params.visaDetailsId).then( res => {
        this.data = res.data.data;
        // this.data.start_date = moment(this.data.start_date).format('DD-MM-YYYY')
        // this.data.end_date = moment(this.data.end_date).format('DD-MM-YYYY')
        this.clientData = res.data.data.client;
        this.clientData.contacts = JSON.parse(this.clientData.contacts);
        this.clientData.given_date = moment(this.clientData.given_date).format('DD-MM-YYYY');

        // signpost-split 
      })
    },
    methods: {
      handleBack() {
        this.$router.go(-1)
        // this.$router.push({name: 'visa'})
      },
      handleClientVisaUpload() {
        document.getElementById('clientVisaId').click()
      },
      handleVisaUpload(e) {
        this.visaUploadFile = e.target.files[0]
      },
      submitVisaUpload() {
        let formData = new FormData()
        formData.append('visa_id', parseInt(this.$route.params.visaDetailsId));
        formData.append('file', this.visaUploadFile)

        uploadVisaResult(formData).then(res => {
          window.location.reload()
        })
      },
      downloadClientVisa(visa) {
        this.downloadLoading = true;
        getS3PermanentFile({link: visa}).then(response => {
          // var headers = response.headers();
          var blob = new Blob([response.data.link],{type: 'application/jpg'});
          var link = document.createElement('a');
          link.href = response.data.link;
          link.target="_blank"
          link.click();
          this.downloadLoading = false;
        })
      } 
    }
  }
</script>